<script>
/**
 * Save & set a def`s to evacoffensejournal fields at last input 
 * (append mode only)
 */    

import { isEmpty as empty } from "@/utils/";
import { distance, lookup } from "@/utils/geo";
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

import VehicleService from '@/services/VehicleService'; 

const $moment = require('moment');
import Inputmask from "inputmask";

import {ebus} from '../../../../main';

/**
 * saved values
 */
const _data = {
    evacoffensejournalOffenseaddress: null,
    evacoffensejournalCityid: null,
    evacoffensejournalParkingid: null,
    evacoffensejournalReasonid: null
};

let _vehicles = [];
let nearestVehicle = null;

const _SIN2_VIEWS_IDS = {
    marks:  {uri: 'sin2:/v:f0229c5d-a121-4c4a-90a8-eae854f9f272/?sort=evacVehicleKindName.vehicleKindName', field: "vehiclekindname", data: null},
};

const EVComboInput = {
    name: 'EVComboInput',
    vuetify,
    el: null,
    props: ['ikey', 'label', 'value', 'comp'],
    inject: ['owner'],
    data(){
        return {
            $value: null,
            items: null
        };
    },
    created(){
        this.$value = this.value;
        if (!!_SIN2_VIEWS_IDS[this.ikey].data){
            this.items = _SIN2_VIEWS_IDS[this.ikey].data;
            return;
        }
        jet.http.post({type:"core-read", query: this.uri}).then( res => {
            if (!!res.error){
                throw res.error;
            }
            _SIN2_VIEWS_IDS[this.ikey].data = jet.http.prepareSinResponse(res);
            this.items = _SIN2_VIEWS_IDS[this.ikey].data;
        }).catch( e => {
            console.log('ERR (causes)', e);
        });
    },
    computed: {
        uri(){
            return _SIN2_VIEWS_IDS[this.ikey].uri;
        },
        field(){
            return _SIN2_VIEWS_IDS[this.ikey].field;
        }
    },
    template: `<v-combobox :label="label"
                           v-model="$value"
                           :item-text="field"
                           :item-value="field"
                           :return-object="false"
                           :items="items"
                           required
                           v-on:change="change">
               </v-combobox>`
};  //EVComboInput

const EVButton = {
    name: 'EVButton',
    vuetify,
    el: null,
    props: ['btnText'],
    data() {
        return {
            load: false,
            disable: false
        };
    },
    computed: {
        loading: {
            get() {
                return this.load;
            },
            set(value) {
                this.load = value;
            }
        },
    },
    template: `<v-btn
                    outlined
                    color="primary"
                    class="mr-2"
                    tile
                    small
                    :loading="loading"
                v-on:click="click">
                    {{ btnText }}
               </v-btn>`
}; //EVButton

export default {
    created(){
        this.onDataChange = e => {
            Object.keys(_data).map( k=> {
                if (
                        ( k === e)
                     &&!empty(this[k])
                ){
                    _data[k] = this[k];
                }
            });
        };  //onDataChange
        
        //restore saved value`s
        if ( !(!!this?.evacoffensejournalId) ){
            Object.keys(_data).map( k=> {
                if (
                        empty(this[k])
                     &&!empty(_data[k])
                ) {
                    this[k] = _data[k];
                }
            });
            if ( empty(this["evacoffensejournalArrivaltime"]) ){
                this["evacoffensejournalArrivaltime"] = $moment().add(30, 'm').format("HH:mm");
            }
        }
    },
    mounted(){
        const self = this;
        ebus.$on('changeSelectVehi', newSelectVehi => {
            const vehiclesList = self.$refs['evacoffensejournalVehicleevacid'];
            vehiclesList.setValue(newSelectVehi.id);
            vehiclesList.selected = newSelectVehi;
            vehiclesList.text = newSelectVehi.govnum;
            vehiclesList.validate();
        });
        this.$parent.afterSave = id => {
            if ( typeof jet.evanotify !== "undefined" ){
                jet.evanotify(id);  //by EvaJ.created
            } else {
                console.log('No notify');
            }
        };
        this.$parent.beforeSave = async () => {
            const req = {
                type: 'query',
                query: 'de00c22a-0594-4c4a-bc9d-d4484b957355.checkEvac',
                params: {
                    vcID: this['evacoffensejournalVehicleevacid']
                }
            };
            const res = await jet.http.post('/rpc/?d=jsonRpc', req);
            if (!res.error) {
                const data = res.result.data;
                if ( data.length > 0 ) {
                    const ok = await jet.confirm({title: "Проверка эвакуатора", msg: `У эвакуатора есть активные заявки. Продолжить?`});
                    return ok;
                }
            }
            return true;
        };
        this.$nextTick( async () => {
            
            console.log('EvaJForm', this["evacoffensejournalId"], this);
            const isNew = empty( this["evacoffensejournalId"] );
            
            //Время
            $(".jet-input label").each( function(){
                const l = $(this);
                if ( /(врем.{1,}прибытия)+/gi.test( l.text() ) ){
                    Inputmask({"mask": "99:99"}).mask( $(`#${l.attr("for")}`).get(0) );
                }
            });
            

            // preload vehicles
            const vehiclesList = self.$refs['evacoffensejournalVehicleevacid'];
            try {
                await vehiclesList.refresh();
            } catch(e){
                console.log('ERR (vehicles)', e);
            }
            
            
            // Марка/модель ---------------------------------------------------
            const marks = this.$refs["evacoffensejournalVehiclekindname"];
            $(marks.$el).addClass("d-none");
            $(marks.$el).parent().append('<div id="vcmarks"></div>');
            
            const compM = new Vue(Object.assign({
                parent: this,
                propsData: {
                    ikey: 'marks',
                    label: 'Марка / модель',
                    value: this.evacoffensejournalVehiclekindname,
                    comp:  marks
                },
                methods: {
                    change(val){
                        marks.setValue.call(marks, val);
                        marks.validate();
                    }
                }
            }, EVComboInput)).$mount( $(marks.$el).parent().find("#vcmarks").get(0) );

            // Кнопка Ближайший ---------------------------------------------------
            $(this.$el).find(".v-tabs-items .v-window-item--active .container").append('<div class="row"><div id="btn-adds" class="flex offset-xs1"></div></div>'); 
            const btnConte = $(this.$el).find("#btn-adds");
            btnConte.append('<div id="nearest"></div>');
            const btnM = new Vue(Object.assign({
                parent: this,
                propsData: {
                    btnText: "Ближайший"
                },
                methods: {
                    async click() {
                        try {
                            this.loading = true;
                            
                            const res = await self.defineNearest();
                            if (!res.vehicle){
                                jet.msg({text: "Нет данных для поиска ближайшего эвакуатора"});
                                return;
                            }
                            
                            const distance = res.dist;
                            nearestVehicle = res.vehicle;
                            console.log('nearestVehicle', nearestVehicle);
                            
                            jet.msg({text: `Ближайший: ${ nearestVehicle.vcvehicleGovnum }, расстояние: ${ self.defineDistance(distance) }`, color:'primary'});

                            const vehicleList = self.$refs['evacoffensejournalVehicleevacid'];
                            vehicleList.rowSelect(nearestVehicle);
                            vehicleList.validate();
                            this.loading = false;
                        } catch(e) {
                            console.log('ERR (nearest)', e);
                            if (
                                    /(no\saddr)+/i.test(e.message)
                               ){
                                
                            } else {
                                jet.msg({text: "Не удается определить ближайший эвакуатор"});
                            }
                        } finally {
                            this.loading = false;
                        }
                    }
                }
            }, EVButton)).$mount(btnConte.find("#nearest").get(0));

            
            btnConte.append('<div id="displayMap"></div>');
            const btnM2 = new Vue(Object.assign({
                parent: this,
                propsData: {
                    btnText: "Показать на карте",
                },
                methods: {
                    async click() {
                        try {
                            let nearestVehicleId = null;

                            if(!!nearestVehicle) {
                                nearestVehicleId = nearestVehicle.id;
                            } else {
                                const currentVehicleId = self.$refs["evacoffensejournalVehicleevacid"].value;
                                const vehicleList = self.$refs['evacoffensejournalVehicleevacid'].getItems();
                                const vehicle = vehicleList.find(item => item.vcvehicleId === currentVehicleId);
                                nearestVehicleId = vehicle.vcvehicleId;
                            }

                            jet.collections.open({id: "4a409336-70d8-4e77-9ab3-c144c8e1f253", name: "Карта", uri: "catrem:/?mode=evac&f:vcTypes=e30d37ea-7f3f-4b51-9b4d-840b702a9ec4"});

                            this.$nextTick(async () => {
                                const map = jet.collections.active;
                                
                                try {
                                    const res = await map.master.extend.onAfterLoad();

                                    if(res) {
                                        map.master.extend.selectNearestVehi(nearestVehicleId);
                                    }
                                    
                                } catch (error) {
                                    console.error('ERR: Display Map');
                                }
                            })
                        } catch (error) {
                            console.log('ERR (on EvaJForm)', error);
                        }
                        
                    }
                }
            }, EVButton)).$mount(btnConte.find("#displayMap").get(0));
            const _disp_coords = coords => {
                this.$refs["evacoffensejournalOffenseaddress"].set("messages", [
                    `${Number(coords.lat).toFixed(6)}, ${Number(coords.lon).toFixed(6)}`
                ]);
            };
            
            //check coords/addr 
            if ( !isNew ) {
                if ( !empty(this.$refs["evacoffensejournalOffenseaddress"].val) ){
                    if (!this.$refs["evacoffensejournalLon"]?.val){
                        setTimeout(async ()=>{
                            const ok = await jet.confirm({title: "Адрес", msg: `Координаты адреса "${this.$refs["evacoffensejournalOffenseaddress"].val}" не определены - распознать?`});
                            if (ok){
                                const coords = await this.getCoords();
                                console.log('coords', coords);
                                if( coords.resolve ) {
                                    this.$refs["evacoffensejournalLon"].setValue(coords.lon);
                                    this.$refs["evacoffensejournalLon"].validate();
                                    this.$refs["evacoffensejournalLat"].setValue(coords.lat);
                                    this.$refs["evacoffensejournalLat"].validate();
                                    _disp_coords(coords);
                                } else {
                                    jet.msg({text:"Введеный адрес не распознан", color: "warning"});
                                }
                            }
                        }, 300);
                    } else {
                        _disp_coords({
                                        lon: this.$refs["evacoffensejournalLon"].val, 
                                        lat: this.$refs["evacoffensejournalLat"].val
                                    });
                    }
                }
            }
        });     //nextTick
    },
    methods: {
        async getCoords(){
            var coords = { resolve: false };
            if (!!this.$refs["evacoffensejournalLon"]?.val){
                coords.lon = this.$refs["evacoffensejournalLon"].val;
                coords.lat = this.$refs["evacoffensejournalLat"].val;
                coords.resolve = true;
            } else {
                var a = this.$refs["evacoffensejournalCityid"].values('city');
                a = (empty(a) ? "" : a + ", ") + this.$refs["evacoffensejournalOffenseaddress"].val;
                const _coords = await lookup(a);
                if( _coords?.length > 0 ) {
                    coords = _coords[0];
                    coords.resolve = true;
                }
            }
            return coords;
        },
        async defineNearest(){
            return new Promise( async (resolve, reject) => {
                try {
                    // Координаты
                    const coords = await this.getCoords();
                    if (!coords.resolve){
                        jet.msg({text: `Введеный адрес ${this.$refs["evacoffensejournalOffenseaddress"].val} не распознан`, color: "warning"});
                        throw {message: 'no addr'};
                    }
                    
                    // Получаем список ТС
                    if (_vehicles.length < 1){
                        _vehicles = this.$refs['evacoffensejournalVehicleevacid'].getItems();
                    }
                    // Запрашиваем по списку Last Position
                    var all = [];
                    for (var i = 0; i < Math.ceil(_vehicles.length/10); i++){
                        all[i] = _vehicles.slice((i*10), (i*10) + 10);
                    }

                    await Promise.all(
                                        all.map(async vehicle => {
                                            var ids = vehicle.map(v => v.vcvehicleId);
                                            await this._get_last_positions(ids);
                                        })
                    );
                    
                    // Определяем эвакуаторы, которые на связи
                    const online = _vehicles.map( v => {
                        v.id = v.vcvehicleId;
                        if (!!v.telemetry){
                            v.distance = distance(v.telemetry, coords);
                        } else {
                            v.distance = Number.MAX_VALUE;
                        }
                        return v;
                    }).filter(v => {
                        return !!v.telemetry && !!$moment(v.telemetry.time).isAfter($moment().subtract(15, 'minutes')) && v.vcvehicleCountactive == 0;
                    }).sort( (v1, v2) => {
                        return v1.distance - v2.distance;
                    } );
                    console.log(online);
                    if ( online.length > 0 ){
                        resolve({vehicle: online[0], dist: online[0].distance});
                    } else {
                        throw {message: 'no online vechicles'};
                    }
                    
                } catch(e) {
                    console.error('ERR (defineNearest)', e);
                    reject(e);
                }
            });
        }, // defineNearest
        async _get_last_positions(ids) {
            try {
                const vehiclesLastInfo = await VehicleService.getLastInfo(ids);
                if ( !!vehiclesLastInfo ) {
                    vehiclesLastInfo.forEach( tm => {
                        const n = _vehicles.findIndex( vc => (vc.vcvehicleId === tm.deviceId) || (vc.vcvehicleId === tm.id) );
                        if (n > -1){
                            tm.heading += 180;
                            _vehicles[n].telemetry = tm;
                        }
                    });
                }
            } catch(e) {
                console.error('ERR: _get_last_positions', e);
            }
        }, // _get_last_positions
        defineDistance(distance) {
            console.log('distance', distance);
            if(distance < 1000) {
                return distance + " м";
            } else {
                return Number.parseFloat(distance/1000).toFixed(1) + " км";
            }
        }, // defineDistance

    }
    
};
</script>
