<script>
const _data = {
    oldValue : null,
    newValue : null,
    id       : null
};
let editConte = null;

export default {
    created() {
        editConte = this.$parent.$parent.$parent;
        this.onDataChange = e => {
            if ( e == 'evacvehiclekindnameVehiclekindname' && !!this[e] ) {
                _data.newValue = this[e];
            }
        };  //onDataChange
    },
    mounted() {
        _data.oldValue = this['evacvehiclekindnameVehiclekindname'];
        _data.id       = this['evacvehiclekindnameId'];
        this.$parent.beforeSave = async () => {
            if ( _data.oldValue != _data.newValue ) {
                const req = {
                    type: 'query',
                    query: 'b4cec46c-d239-40d0-8795-c9ef5e007d7d.updateNames',
                    params: {
                        new: _data.newValue,
                        old: _data.oldValue
                    },
                };
                const res = await jet.http.post('/rpc/?d=jsonRpc', req);
            }
            editConte.$emit('hide');
            editConte.$emit('data-change', {id: _data.id});
            return false;
        };
    }
}
</script>