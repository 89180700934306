<script>
export default{
    name: 'evacReport8',
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    mounted(){
        console.log('evacreport', this.owner);
        this.journalID = this.$store.getters['cache/getItem']('evacReport8_ID');

        var comp = this.owner.$refs["regNum"];
        comp.setDisabled(true);
        comp.setValue(this.journalID.evacoffensejournalRegnum);

        var comp = this.owner.$refs["protocolDt"];
        comp.setValue(new Date(this.journalID.evacoffensejournalCreatedt));
        comp.$attrs.value = new Date(this.journalID.evacoffensejournalCreatedt);
        comp.text = $moment(this.journalID.evacoffensejournalCreatedt).format('DD.MM.YYYY');

        var comp = this.owner.$refs["journalID"];
        comp.setVisible(false);
        comp.setValue(this.journalID.evacoffensejournalId);
    },
    data: () => ({
        /*params*/
        journalID: null,
        regDt    : null
    }),
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>