<template>
    <v-card class="mt-6" outlined v-if="!!stat">
        <v-card-title style="background-color: #E3F2FD;" class="pa-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small
                        :color="(!!state) ? ((state.TaskCount - state.CompletedTaskCount == 0) ? 'green' : 'red'): ''"
                    >
                        mdi-information-outline
                    </v-icon>
                </template>
                <span v-html="(!!state) ? state.state : 'Расчет не запущен'"></span>
            </v-tooltip>
            <span class="primary--text ml-4 text-subtitle-2">Информация</span>
            <v-spacer/>
            <v-btn icon small
                :loading="loading"
                v-on:click="_refresh">
                <v-icon small color="grey">mdi-reload</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="mt-6">
            <v-row>
                <v-col cols="4">Посдений период расчета:</v-col>
                <v-col class="primary--text">{{ stat.regdt }}</v-col>
                <v-col cols="3">Дата расчета:</v-col>
                <v-col class="primary--text">{{ stat.regtimestamp }}</v-col>
            </v-row>
            <div class="primary--text text-subtitle-2 mt-4" v-if="stat.queue.length>0">
                <v-icon small color="red">mdi-information-outline</v-icon>
                <span class="ml-4">Были изменения. Требуется пересчитать.</span>
            </div>
            <v-sheet height="140" class="overflow-auto">
                <v-list dense nav>
                    <v-list-item v-for="(q, i) in stat.queue" :key="i">
                        <v-row>
                            <v-col cols="6">{{ q.shortname }}</v-col>
                            <v-col class="primary--text">с {{ q.regdt }}</v-col>
                        </v-row>                    
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn small tile color="primary" v-on:click="_on_prepare_q" v-if="stat.queue.length>0">
                <v-icon small>mdi-flash-outline</v-icon>
                Рассчитать все
            </v-btn>
            <v-btn small tile color="primary" v-on:click="dialog=!dialog">
                <v-icon small>mdi-flash-outline</v-icon>
                Рассчитать перевозчика
            </v-btn>
        </v-card-actions>
        <v-dialog persistent v-model="dialog" max-width="600">
            <v-form ref="formprepare">
                <v-card>
                    <v-toolbar color="primary" dark>
                        Расчет данных отчета 10.44
                    </v-toolbar>
                    <v-card-text>
                        <jet-input ref="crrforcalc" label="Перевозчик" type="id" :required="false" uri="sin2:/v:48501949-d46b-4ae1-8318-7e445fa0b3e9" v-model="carrierID"/>
                        <jet-input label="Дата с" type="date" :required="true" v-model="carrierDt"/>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn outlined tile color="gray" small @click="dialog=!dialog">Отменить</v-btn>
                        <v-btn outlined tile color="primary" small @click="_on_prepare">Применить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-card>
</template>
<script>
import JetInput from '@/components/editors/JetInput';
import { NULL_ID } from "@/utils";
export default{
    components: { JetInput },
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    components: { JetInput },
    data: () => ({
        /*params*/
        dateFrom: null,
        dateTo: null,
        carrier: null,
        /*params filters*/
        f_route_carrier_not_null: null,
        f_route_carrier_null: null,
        f_vehicle_carrier_not_null: null,
        f_vehicle_carrier_null: null,
        f_carrier: null,
        f_routeTypeGroupsID: null,
        stat: null,
        loading: false,
        dialog: false,
        carrierID: null,
        carrierDt: null,
        state: null
    }),
    created(){
        console.log('10.44 created by', this.owner);
        
        this._setInitialParams();
        this._initingFilters();
        this._setDates();
        this.owner.on_set = this._set;
    },
    mounted() {
        this._refresh();
    },
    methods: {
        async _refresh() {
            this.loading = true;
            try {
                const opts = {
                    type: 'query',
                    query: "fcf2cae2-3ecb-46ca-95c9-96a3683d9011.getReport1044Stat",
                    params: {
                        crrID: this.carrier || NULL_ID
                    }
                };
                const r = await $http.post(opts);
                if ( !!r.result.data ) {
                    this.stat = JSON.parse(r.result.data[0]);
                    this.stat.regdt = $moment(this.stat.regdt).format('DD.MM.YYYY');
                    this.stat.regtimestamp = $moment(this.stat.regtimestamp).format('DD.MM.YYYY HH:mm');
                    if ( !!this.stat.queue) {
                        this.stat.queue.forEach((q) => {
                            q.regdt = $moment(q.regdt).format('DD.MM.YYYY');
                        });
                    } else {
                        this.stat.queue = [];
                    }
                };
                this.state = JSON.parse(await jet.http.get(`/api/activity/state`));
                if ( !!this.state ) {
                    this.state.state = (this.state.ActiveCount > 0) ? 'Расчет запущен' : 'Расчет не запущен';
                } else {
                    this.state.state = 'Расчет не запущен';
                }
            } catch(e) {
                console.log('Err stat', e);
            } finally {
                this.loading = false;
            }
        },
        async _on_prepare_q() {
            jet.http.get(`/api/activity/prepare_q`);
        },
        async _on_prepare() {
            if ( !this.$refs.formprepare.validate() )
                return;
            const opts = `carrierID=${(!!this.carrierID) ? this.carrierID : ""}&dateFrom=${$moment(this.carrierDt).format('YYYY-MM-DD')}&dateTo=${$moment().format('YYYY-MM-DD')}`;
            jet.http.get(`/api/activity/prepare?${opts}`);
            this.dialog = false;
            this._refresh();
        },
        _setInitialParams(){
            var comp = this.owner.$refs["dateFrom"];
            this.dateFrom = this._setDateFormat(comp.value);
            comp = this.owner.$refs["dateTo"];
            this.dateTo = this._setDateFormat(comp.value);
        },
        _completingFilters(param){
            param = param.replaceAll(`param_carrier`, this.carrier)
                        .replaceAll(`param_dateFrom`, this.dateFrom)
                        .replaceAll(`param_dateTo`, this.dateTo);
            return param;
        },
        _setDateFormat(param){
            var result = null;
            if (param !== null) {
                result = $utils.formatDate(new Date(param), 'DD-MM-YYYY HH:mm:ss');
            }
            return result;
        },
        _set(param){
            console.log('10.44.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "carrier":
                    this.carrier = param.value;
                    this._setCarrier();
                    this._refresh();
                    if ( !this.carrierID && this.$refs['crrforcalc'] && this.carrier ) {
                        this.$refs['crrforcalc'].value = this.carrier;
                    }
                    break;
                case "dateFrom":
                    this.dateFrom = this._setDateFormat(param.value);
                    this._setDates();
                    break;
                case "dateTo":
                    this.dateTo = this._setDateFormat(param.value);
                    this._setDates();
                    break;
            }
        },
        _setDates(){
            if (!$utils.isEmpty(this.dateFrom) && !$utils.isEmpty(this.dateTo)) {
                var comp = this.owner.$refs["carrier"];
                var filter = this._completingFilters(this.f_carrier);
                comp.setFilter(filter);
                comp = this.owner.$refs["routeTypeGroupsID"];
                filter = this._completingFilters(this.f_routeTypeGroupsID);
                comp.setFilter(filter);
                this._setCarrier();
            }
        },
        _setCarrier(){
            if (!$utils.isEmpty(this.dateFrom) && !$utils.isEmpty(this.dateTo)) {
                var comp = this.owner.$refs["route"];
                var filter = '';
                if (!$utils.isEmpty(this.carrier)) {
                    filter = this._completingFilters(this.f_route_carrier_not_null);
                } else {
                    filter = this._completingFilters(this.f_route_carrier_null);
                }
                comp.setFilter(filter);

                comp = this.owner.$refs["vehicle"];
                if ( !!comp ) {
                    filter = '';
                    if (!$utils.isEmpty(this.carrier)){
                        filter = this._completingFilters(this.f_vehicle_carrier_not_null);
                    } else {
                        filter = this._completingFilters(this.f_vehicle_carrier_null);
                    }
                    comp.setFilter(filter);
                }
            }
        },
        _initingFilters(){
            this.f_route_carrier_not_null = 
                    `and(
                        not(eq(field(\".verStatus.Name\"),param(\"Проект\", \"string\"))),
                        exists(\"e8fd22eb-f901-42af-874f-2bf5fe6a78f4\", \"
                            and(
                                eq(field(\\\".carrier\\\"),param(\\\"param_carrier\\\", \\\"id\\\")),
                                eq(super.field(\\\".ID\\\"),field(\\\".route\\\")),
                                or(
                                    isnull(field(\\\".endDt\\\")),
                                    gte(field(\\\".endDt\\\"),param(\\\"param_dateFrom\\\", \\\"date\\\"))
                                ),
                                lte(field(\\\".startDt\\\"),param(\\\"param_dateFrom\\\", \\\"date\\\"))
                            )
                        \"),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        or(
                            isnull(field(\".verEndTime\")),
                            gte(field(\".verEndTime\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".verStart\"))),
                            lte(field(\".verStart\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".startDt\"))),
                            lte(field(\".startDt\"),param(\"param_dateFrom\", \"date\"))
                        )
                    )`;
            this.f_route_carrier_null = 
                    `and(
                        not(eq(field(\".verStatus.Name\"),param(\"Проект\", \"string\"))),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        or(
                            isnull(field(\".verEndTime\")),
                            gte(field(\".verEndTime\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".verStart\"))),
                            lte(field(\".verStart\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".startDt\"))),
                            lte(field(\".startDt\"),param(\"param_dateFrom\", \"date\"))
                        )
                    )`;
            this.f_vehicle_carrier_not_null = 
                    `and(
                        eq(field(\".crrID\"),param(\"param_carrier\", \"id\")),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_dateTo\", \"date\"))
                    )`;
            this.f_vehicle_carrier_null = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_dateTo\", \"date\"))
                    )`;
            this.f_carrier = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_dateTo\", \"date\"))
                    )`;
            this.f_routeTypeGroupsID = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_dateFrom\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_dateTo\", \"date\"))
                    )`;
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>